<template>
  <div id="verifikasi-nasional-distribusi-send-doc-list" data-app>
    
    <div class="card card-custom border-lg border-0 border-primary">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Verifikasi Distribusi
          </h3>
        </div>
      </div>

      <!--begin::Table-->
        <div class="card-body pt-0 pb-0">

          <b-row class="mt-3 mb-2">
            <b-col cols="12" sm="8">
              <button @click="dialogFilter = true" class="btn btn-light-success btn-sm rounded font-weight-bolder font-size-sm px-4 p-2 mx-1">
                <span class="svg-icon svg-icon-sm">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Text/Filter.svg"/>
                    <!--end::Svg Icon-->
                </span>          
                Filter
              </button>
              <button v-if="selected.length" @click="verifikasiMultiData('verif')" class="btn btn-light-primary btn-sm rounded font-weight-bolder font-size-sm px-4 p-2 mx-1">
                <span class="svg-icon svg-icon-sm">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-check.svg"/>
                    <!--end::Svg Icon-->
                </span>          
                Verifikasi
              </button>
              <button v-if="selected.length" @click="verifikasiMultiData('tolak')" class="btn btn-light-danger btn-sm rounded font-weight-bolder font-size-sm px-4 p-2 mx-1">
                <span class="svg-icon svg-icon-sm">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-disabled.svg"/>
                    <!--end::Svg Icon-->
                </span>          
                Tolak
              </button>
            </b-col>
          </b-row>

          <v-data-table
            v-model="selected"
            item-key="_id"
            show-select
            :headers="fields"
            :items="items"
            :page.sync="currentPage"
            :items-per-page="perPage"
            hide-default-footer
            class="elevation-1"
            disable-sort
            :loading="isBusy"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.rincian`]="{ item }">
              <span>  
                {{ item.perusahaan.nama }}, 
                Haychery {{ item.hatchery.nama }}
              </span>
            </template>
            
            <template v-slot:[`item.send_doc`]="{ item }">
              <span>  
                {{ item.customer }}, Tujuan
                Prov. {{ item.provinsi.uraian }},
                {{ item.kabupaten.uraian }},
                Kec. {{ item.kecamatan.uraian }},
                Ds. {{ item.desa.uraian }}
              </span>
            </template>

            <template v-slot:[`item.rincian_sum_jumlah`]="{ item }">
                {{ item.rincian_sum_jumlah | nominal }} ekor,
                Tgl Distribusi {{ item.tgl_distribusi | dateIndonesia }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <b-button 
                v-b-tooltip.hover title="Lihat Send DOC"
                class="btn-icon mr-1"
                variant="clean"
                @click="$router.push(`/verifikasi/nasional/distribusi/send-doc/${item._id}`)">
                  <span class="svg-icon svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Binocular.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>

                <b-button 
                v-b-tooltip.hover title="Verifikasi Send DOC"
                class="btn-icon mr-1"
                variant="clean"
                @click="verifikasiData({ ...item, status: 'verif' })">
                  <span class="svg-icon svg-icon-primary svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-check.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
                
                <b-button 
                v-b-tooltip.hover title="Tolak Send DOC"
                class="btn-icon"
                variant="clean"
                @click="verifikasiData({ ...item, status: 'tolak' })">
                  <span class="svg-icon svg-icon-danger svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-disabled.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
            </template>
          </v-data-table>
        </div>
      <!--end::Table-->  

        <div class="card-footer">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" align="center"></b-pagination>
        </div>

    </div>

    <v-dialog
    v-model="dialogVerif"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Action data</v-card-title>

        <v-card-text>
          Apakah anda yakin?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogVerif = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onVerif">
            LANJUTKAN
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFilter" persistent scrollable max-width="500px">
      <v-card class="px-4 h-100">
        <v-card-title class="headline">Filter Data Chickin</v-card-title>
      
        <perfect-scrollbar
        class="scroll"
        style="max-height: 89%; position: relative;"
        >
          <ValidationObserver ref="formFilter">
          <b-form @submit="onSubmitFilter" v-if="show">
            <v-card-text>
                <div class="mb-7" role="group">
                <label for="input-filter-1">Tanggal Chickin:</label>
                <b-button id="input-filter-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ showDate }}</b-button>
                </div>

                <validationProvider name="Perusahaan" v-slot="{ errors, valid }">
                    <b-form-group
                        id="input-filter-group-2" 
                        label="Perusahaan:" 
                        label-for="input-filter-2"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                        <b-form-select2
                        id="input-filter-2"
                        v-model="filter.perusahaan"
                        :options="opsPerusahaan"
                        required
                        variant="solid"
                        :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <button 
                @click="onSubmitFilter" 
                ref="kt_submit_form_filter"
                class="btn btn-primary font-weight-bold m-3">
                <i class="la la-save"></i>
                Filter
                </button>

                <button
                class="btn btn-clean font-weight-bold m-3"
                type="button"
                @click="onResetFilter()">
                <i class="la la-recycle"></i>
                Reset
                </button>

                <button
                class="btn btn-clean font-weight-bold m-3"
                type="button"
                @click="dialogFilter = false">
                <i class="la la-close"></i>
                Close
                </button>
            </v-card-actions>
          
          </b-form>
          </ValidationObserver>
        </perfect-scrollbar>
      </v-card>
    </v-dialog>

    <v-dialog
        ref="dialogDate"
        v-model="modalDate"
        persistent
        width="290px"
    >
        <v-date-picker
        v-model="dates"
        scrollable
        range
        color="bg-primary"
        header-color="bg-primary"
        >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="modalDate = false"
        >
            Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="setDate()"
        >
            OK
        </v-btn>
        </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_COUNT } from "@/core/services/store/verification.module";
import VerifikasiService from "@/core/services/api/verifikasi/nasional/distribusi.service";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import ErrorService from "@/core/services/error.service";


export default {
  name: "verifikasi-nasional-distribusi-send-doc-list",
  data() {
    return {
      selected: [],
      show: true,
      modalDate: false,
      dialogFilter: false,
      dates: [],
      filter: {
          tgl_awal: null,
          tgl_akhir: null,
          perusahaan: null
      },
      isBusy: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      fields: [{ value: "rincian", text: "Asal DOC" }, { value: "send_doc", text: "Pengiriman" }, { value: "rincian_sum_jumlah", text: "Jumlah" }, { value: "action", text: "Act" } ],
      items: [],
      dialogVerif: false,
      dataVerif: null,
      Perusahaan: [{
          value: null,
          text: 'Silahkan pilih Perusahaan',
      }],
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentCount"
    ]),
    showDate() {
        return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
    }
  },
  methods: {
    setDate() {
        if (this.dates.length < 1) {
            ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan pilih tanggal terlebih dahulu',
            })
            return;
        }
        if (this.dates.length < 2) {
            this.dates.push(this.dates[0])
        }
        this.dates.sort()
        this.filter.tgl_awal = this.dates[0]
        this.filter.tgl_akhir = this.dates[1]
        this.modalDate = false
        this.errorDate = false
    },
    changePage(val){
      if(this.changePage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      let count = this.currentCount
      count.nasional.distribusi.send_doc = 0
      this.$store.dispatch(UPDATE_COUNT, count);
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
    },
    getPerusahaan() {
      PerusahaanService.getPerusahaan()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPerusahaan = [{
                    value: null,
                    text: "Silahkan pilih Perusahaan"
                }]
                return;
            }

            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
                value: null,
                text: "Silahkan pilih Perusahaan"
            })
        })
        .catch((err) => {
            this.opsPerusahaan = [{
                value: null,
                text: "Tidak ada Perusahaan"
            }]
            ErrorService.status(err)
        })
    },
    getData (page = 1, perPage = this.perPage, search = this.filter) {
      VerifikasiService.getSendDocAll({page, per_page: perPage, ...search})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            let count = this.currentCount
            count.nasional.distribusi.send_doc = res.data.data.total
            this.$store.dispatch(UPDATE_COUNT, count);
            this.isBusy = false
        })
        .catch((err) => {
          this.resetTable()
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
    verifikasiData(val) {
      this.dataVerif = {
        selected: [{
          _id: val._id,
          rincian: `
            ${val.perusahaan}, 
            Haychery ${val.hatchery},
            ${val.customer},
            Tujuan Prov. ${val.provinsi.uraian},
            ${val.kabupaten.uraian},
            Kec. ${val.kecamatan.uraian},
            Ds. ${val.desa.uraian}
            Distribusi ${this.$options.filters.nominal(val.rincian_sum_jumlah)} 
            ekor Tanggal ${this.$options.filters.dateIndonesia(val.tgl_distribusi)}`
        }],
        status: val.status
      }
      this.dialogVerif = true
    },
    verifikasiMultiData(status) {
      if(!this.selected.length) {
        return ErrorService.message({
                    action: 'Peringatan',
                    message: 'Silahkan pilih data terlebih dahulu',
                })
      }
      if(this.selected.length > 20) {
        return ErrorService.message({
                    action: 'Peringatan',
                    message: 'Data yang dipilih tidak boleh lebih dari 10',
                })
      }
      const selecteds = this.selected.map((select) => {
        return {
          _id: select._id,
          rincian: `
            ${select.perusahaan}, 
            Haychery ${select.hatchery},
            ${select.customer},
            Tujuan Prov. ${select.provinsi.uraian},
            ${select.kabupaten.uraian},
            Kec. ${select.kecamatan.uraian},
            Ds. ${select.desa.uraian}
            Distribusi ${this.$options.filters.nominal(select.rincian_sum_jumlah)} 
            ekor Tanggal ${this.$options.filters.dateIndonesia(select.tgl_distribusi)}`
        }
      })
      this.dataVerif = {
        selected: selecteds,
        status
      }
      this.dialogVerif = true
    },
    onVerif () {
      if (! this.dataVerif) {
        return
      }
      VerifikasiService.verifSendDoc(this.dataVerif)
        .then((res) => {
            this.selected = []
            if (res.data.status) { 
              this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogVerif = false
            this.dataVerif = null
        })
        .catch((err) => {
          this.selected = []
          this.dialogVerif = false
          this.dataVerif = null
          return ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
        button.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
    },
    onSubmitFilter(evt) {
        evt.preventDefault()
        this.$refs.formFilter.validate().then(success => {
            if (!success) {
                ErrorService.message({
                    action: 'Peringatan',
                    message: 'Silahkan lengkapi form data terlebih dahulu',
                })
                return;
            }

            // set spinner to submit button
            const submitButton = this.$refs["kt_submit_form_filter"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            // dummy delay
            setTimeout(() => {
                this.dialogFilter = false
                this.getData(1)

                this.removeSpinner(submitButton)
            }, 800);
        })
    },
    onResetFilter () {
        this.filter.tgl_awal = null
        this.filter.tgl_akhir = null
        this.filter.perusahaan = null
        this.dates = []

        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Nasional Distribusi" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
    this.getPerusahaan()
  },
};
</script>

<style lang="scss">
#verifikasi-nasional-distribusi-send-doc-list {
  .v-data-table {
    .v-data-table__wrapper {
      table {
        tbody {
          tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background: none !important;
          } 
        }
      }
    
      .v-data-table__mobile-row {
        min-height: 28px !important;
      }
    }
  }
}
</style>